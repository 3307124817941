@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-message-custom-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-primary {
  background-color: #1e88e5;
  border-color: #1e88e5;
}

.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
  display: none;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.corner-border {
  background: linear-gradient(to right, rgb(71, 85, 105) 2px, transparent 2px) 0
      0,
    linear-gradient(to right, rgb(71, 85, 105) 2px, transparent 2px) 0 100%,
    linear-gradient(to left, rgb(71, 85, 105) 2px, transparent 2px) 100% 0,
    linear-gradient(to left, rgb(71, 85, 105) 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, rgb(71, 85, 105) 2px, transparent 2px) 0 0,
    linear-gradient(to bottom, rgb(71, 85, 105) 2px, transparent 2px) 100% 0,
    linear-gradient(to top, rgb(71, 85, 105) 2px, transparent 2px) 0 100%,
    linear-gradient(to top, rgb(71, 85, 105) 2px, transparent 2px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}

#components-transfer-demo-table-transfer .ant-table td {
  background: transparent;
}
