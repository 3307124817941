.json-tree-view {
  font-family: monospace;
}

.json-node {
  cursor: pointer;
  padding-left: 1rem;
  position: relative;
}

/* .children .json-node:hover {
  background-color: #f0f0f0;
} */

.toggle-icon {
  position: absolute;
  left: -1rem;
}

.key-name {
  font-weight: bold;
}
